import { gql } from '@apollo/client/core';

export const USERS_MINI_GROUPS = gql`
  query UserGroups {
    userGroups {
      administrator {
        user {
          id
          contact {
            firstName
            middleName
            lastName
          }
        }
      }
      users {
        id
        contact {
          firstName
          middleName
          lastName
        }
      }
    }
  }
`;