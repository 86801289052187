import { gql } from '@apollo/client/core';

export const USERS_GROUPS = gql`
  query UserGroups {
    userGroups {
      id
      title
      administrator {
        user {
          id
          avatar
          contact {
            id
            dateOfBirth
            firstName
            middleName
            lastName
            email
            phone
          }
          leaves (order: DESC, filterActive: true) {
            kind
            startDate
            endDate
            deputy {
              id
              displayName
            }
          }
        }
      }
      users {
        id
        avatar
        contact {
          id
          dateOfBirth
          firstName
          middleName
          lastName
          email
          phone
        }
        leaves (order: DESC, filterActive: true) {
          kind
          startDate
          endDate
          deputy {
            id
            displayName
          }
        }
      }
    }
  }
`;