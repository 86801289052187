import PropTypes from 'prop-types';
import React from 'react';

import { CssBaseline } from '@material-ui/core';
import {
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core';
import { green, indigo, red, teal } from '@material-ui/core/colors';

const APP_COLOR = {
  production: teal,
  staging: indigo,
};

const primary = APP_COLOR[process.env.REACT_APP_ENV] || teal;

const palette = {
  primary: {
    main: '#FF6600',
  },
  secondary: {
    main: '#ff7e00',
  },
  success: {
    light: green[50],
    main: green[300],
  },
  error: {
    main: red.A400,
  },
  background: {
    default: '#fff',
  },
  categories: {
    budget: '#1f77b4',
    bank: '#2ca02c',
    core: '#17becf',
    housekeeping: '#fb9a99',
    leasing: '#ff7f0e',
    credits: '#e377c2',
    individuals: '#6a3d9a',
    rent: '#8c564b',
    cash: '#d62728',
    other: '#7f7f7f',
    affiliate_settlements: '#f3dbdb',
    own_funds: '#bcbd22'
    // #b15928
  },
  equifax: {
    '-': '#fff',
    0: '#377d23',
    1: '#8ebd3a',
    2: '#fefe55',
    3: '#f3ad3d',
    4: '#ec622b',
    5: '#ea3323',
    6: '#d52e1f',
    7: '#c0271c',
    8: '#aa2317',
    9: '#951c12',
    A: '#8b0000',
    B: '#7f170f',
    C: '#808080',
    S: '#8d4b19',
    R: '#63175f',
    W: '#000',
    U: '#d42e3b',
    T: '#63643a',
    I: '#2b616a',
  },
  money: {
    income: '#22a053',
    outcome: '#dd5656',
  },
};

const theme = createMuiTheme({
  palette,
  drawerWidth: 280,
  typography: {
    fontFamily: ['Museo Sans Cyrl', 'Helvetica', 'sans-serif'].join(','),
    link: {
      color: primary[700],
      fontSize: 12,
      textDecoration: 'none',
      cursor: 'pointer',
      // fontWeight: 'bold',
      // height: '2em',
      // lineHeight: '2em',
      // textAlign: 'center',
      // width: '2em',
    },
  },
  // breakpoints: ['425px', '768px', '1024px'],
  spacing: [0, 4, 8, 16, 24, 32, 40, 48, 56, 64],
});

function createOverrides(theme) {
  return createMuiTheme({
    ...theme,
    components: {
      MuiCheckbox: { defaultProps: { color: 'primary' } },
      MuiRadio: { defaultProps: { color: 'primary' } },
      MuiLink: { defaultProps: { color: '#1771e6' } },
      MuiCssBaseline: {
        styleOverrides: {
          'html, body, #root': {
            height: '100%',
          },
          b: {
            fontWeight: 500,
          },
        },
      },
    },
    typography: {
      ...theme.typography,
      fontSize: 16,
      h1: {
        fontSize: 28,
        fontWeight: 700,
      },
      h2: {
        fontSize: 24,
        fontWeight: 500,
      },
      h3: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: 500,
      },
      h4: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 300,
      },
      h5: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 300,
      },
      h6: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 500,
      },
      // subtitle1: {
      //   // fontSize: theme.typography.pxToRem(13),
      // },
      // subtitle2: {
      //   // fontSize: theme.typography.pxToRem(12),
      // },
      // body1: {
      //   // fontSize: theme.typography.pxToRem(13),
      // },
      // body2: {
      //   // fontSize: theme.typography.pxToRem(12),
      // },
      caption: {
        fontSize: theme.typography.pxToRem(13),
      },
    },
  });
}

export const crmTheme = responsiveFontSizes(createOverrides(theme));

export default function ThemeWrapper({ children }) {
  return (
    <ThemeProvider theme={crmTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

ThemeWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
