/* eslint-disable react/display-name, react/prop-types */
import React from 'react';

import { Link, Typography, Box } from '@material-ui/core';
import dayjs from 'dayjs';
import { FormattedMessage } from "react-intl";

import { formatDate } from 'utils/dateTimeFormatters';

import birthday from './images/birthday.svg';

export const DEALS_PAGE_LIMIT = 20;

export const TABS = ['STRUCTURE', 'TEMPLATES', 'HOLIDAYS'];

export const TYPE_HOLIDAYS = ['DAY_OFF', 'SICK', 'VACATION', 'DISMISSAL' ]

export const COLUMN_EXTENSIONS_STRUCTURE_USERS = [
  {
    columnName: 'user',
    wordWrapEnabled: true,
    width: 300,
  },
  {
    columnName: 'birth',
    wordWrapEnabled: true,
    align: 'center',
    width: 160,
  },
  {
    columnName: 'activity',
    wordWrapEnabled: true,
    align: 'center',
  },
  {
    columnName: 'days',
    wordWrapEnabled: true,
    align: 'center',
    width: 140,
  },
  {
    columnName: 'deputy',
    wordWrapEnabled: true,
    align: 'right',
  },
];

export const COLUMNS_MODEL_CREATOR_STRUCTURE_USERS = [
  { name: 'user', title: 'ФИО' },
  {
    name: 'birth',
    title: 'Дата рождения',
    getCellValue: ({ contact }) => {
      let now = dayjs().format('DD.MM');

      return (
        <Box alignItems={"center"}>
          {contact?.dateOfBirth && (
            <>
              {now === dayjs(contact?.dateOfBirth).format('DD.MM') && (
                <Box alt="Сегодня у него день рождения!" component="img" height={20} src={birthday} />
              )}

              <Typography>
                {formatDate(contact?.dateOfBirth)}
              </Typography>
            </>
          )}
        </Box>
      )
    },
  },
  {
    name: 'activity',
    title: 'Активность',
    getCellValue: ({ leaves }) => {
      let leave = null;

      if (leaves && leaves.length > 0) {
        leave = leaves[0]
      }

      return (
        <Box alignItems={"center"}>
          {leave?.kind && (
            <FormattedMessage id={`user.status.${leave.kind}`} />
          )}
        </Box>
      )
    },
  },
  {
    name: 'absence',
    title: 'Отсутствие',
    getCellValue: ({ leaves }) => {
      return (
        leaves.map(leave => {
          return (
            <Box key={leave?.startDate + leave.endDate}>
              {leave?.startDate && (
                <>
                  c {formatDate(leave.startDate)}
                </>
              )}
              {leave?.endDate && (
                <>
                  {" "} по {formatDate(leave.endDate)}
                </>
              )}
            </Box>
          )
        })
      )
    },
  },
  {
    name: 'days',
    title: 'Кол-во отпускных',
    getCellValue: ({ leaves }) => {
      return (
        leaves.map(leave => {
          return (
            <Box alignItems={"center"} key={leave?.startDate + leave?.endDate}>
              {dayjs(leave.endDate).diff(dayjs(leave.startDate), 'day') + 1}
            </Box>
          )
        })
      )
    },
  },
  {
    name: 'deputy',
    title: 'Заместитель',
    getCellValue: ({ leaves }) => {
      let leave = null;
      if (leaves && leaves.length > 0) {
        leave = leaves[0]
      }

      return (
        leave?.deputy && (
          <Link href={`/collaborators/holiday/${leave.deputy.id}`}>
            <Typography>
              {leave.deputy.displayName}
            </Typography>
          </Link>
        )
      )
    },
  },
];

export const COLUMN_EXTENSIONS_HOLIDAY_USER = [
  {
    columnName: 'actions',
    wordWrapEnabled: true,
    align: 'right',
  },
];

export const COLUMNS_MODEL_CREATOR_HOLIDAY_USER = [
  {
    name: 'holyday',
    title: 'Срок',
    getCellValue: ({ endDate, startDate }) => {
      return (
        <Box>
          {startDate && (
            <>
              c {formatDate(startDate)}
            </>
          )}
          {endDate && (
            <>
              {" "} по {formatDate(endDate)}
            </>
          )}
        </Box>
      )
    },
  },
  {
    name: 'type',
    title: 'Тип',
    getCellValue: ({ kind }) => {
      return <FormattedMessage id={`user.status.${kind}`} />
    }
  },
  { name: 'deputy', title: 'Заместитель'},
  { name: 'actions', title: 'Действия' },
];