import PropTypes from "prop-types";
import React, {useCallback, useContext, useEffect, useState} from 'react';

import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
  DataTypeProvider,
  RowDetailState
} from "@devexpress/dx-react-grid";
import {
  Grid,
  TableHeaderRow,
  VirtualTable
} from "@devexpress/dx-react-grid-material-ui";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import dayjs from "dayjs";
import { cloneDeep, findIndex } from "lodash";
import {FormattedMessage} from "react-intl";

import DetailsModalInfUser from "./DetailsModalInfUser";
import AutocompleteTaskUsers from "../../../components/AutocompleteTaskUsers";
import { DatePickerBox } from "../../../components/DatePickerBox";
import ErrorAlert from "../../../components/ErrorAlert";
import { AppContext } from "../../../core/AppContext";
import { DATE_TIME_FORMAT_NOT_Z } from "../../../core/constants/Formats";
import { formatDate } from "../../../utils/dateTimeFormatters";
import { GridRoot, Loader } from "../../deals/components/helpers";
import { CollaboratorsContext } from "../CollaboratorsContext";
import CollaboratorsTabs from "../CollaboratorsTabs";
import {
  COLUMN_EXTENSIONS_HOLIDAY_USER,
  COLUMNS_MODEL_CREATOR_HOLIDAY_USER,
  TABS, TYPE_HOLIDAYS
} from "../constants";
import { CREATE_HOLIDAY } from "../graphql/mutations/createHoliday";
import { DELETE_HOLIDAY } from "../graphql/mutations/deleteHoliday";
import { LINK_HOLIDAY } from "../graphql/mutations/linkHoliday";
import { UNLINK_HOLIDAY } from "../graphql/mutations/unlinkHoliday";
import { UPDATE_HOLIDAY } from "../graphql/mutations/updateHoliday";
import { GET_USER_HOLIDAYS } from "../graphql/queries/userHolidays";
import { USERS_MINI_GROUPS } from "../graphql/queries/usersMiniGroups";
import PageGraphHolidays from "../PageGraphHolidays";
import PageTemplate from "../PageTemplate";
import ActionsHoliday from "../TableProvidererActionHoliday";
import DetailInfUser from "../TableProvidererInfUserHoliday";

export const DocumentActionTypeProviderHoliday = (props) => (
  <DataTypeProvider
    formatterComponent={(innerProps) => <ActionsHoliday {...{ ...innerProps, ...props }} />}
    {...props}
  />
);

export const DocumentActionTypeProviderDetailUser = (props) => (
  <DataTypeProvider
    formatterComponent={(innerProps) => <DetailInfUser {...{ ...innerProps, ...props }} />}
    {...props}
  />
);

HolidayUser.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default function HolidayUser({ userId }) {
  const { appData } = useContext(AppContext);

  const { state } = useContext(CollaboratorsContext);

  const styles = {
    wrapperTable: {
      overflow: 'auto',

      '& table': {
        minWidth: '900px',
      },
      '& table thead span': {
        color: "#6E7884 !important",
      },
    },
    wrapperContent: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '24px',
      padding: '0',
      background: '#ffffff',
      borderRadius: '16px',
      boxShadow: '0px 2px 6px rgba(13, 29, 50, 0.12)',
    },
    wrapperRadio: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '23px',
    },
    buttonSend: {
      border: 'none',
      fontSize: '14px',
      fontWeight: 400,
      color: '#ffffff',
      padding: '15px 26px',
      backgroundColor: '#3D6CE7',
      borderRadius: '8px',
      marginRight: '10px',
      cursor: 'pointer',

      '&:disabled': {
        opacity: '0.8',
        cursor: 'default',
      }
    }
  };

  const [ selectIdHoliday, setSelectIdHoliday ] = useState(null);

  const [fetchMessages, { data, error, loading }] = useLazyQuery(GET_USER_HOLIDAYS);

  const { data: userMiniGroups } = useQuery(USERS_MINI_GROUPS);

  const [ createHoliday ] = useMutation(CREATE_HOLIDAY,
    {
      onCompleted: (res) => {
        const newRows = cloneDeep(rows);

        newRows.unshift(res.userLeaveCreate.leave)
        setRows(newRows);
        clearParamsHoliday();
      },
      onError: (error) => {
        alert(`Произошла ошибка при добавлении ${error.message}`);
      },
    },
  );

  const [ updateHoliday ] = useMutation(UPDATE_HOLIDAY,
    {
      onCompleted: (res) => {
        const newRows = cloneDeep(rows);
        let indexElem = findIndex(newRows, ['id', res.userLeaveUpdate.leave.id]);

        newRows[indexElem] = res.userLeaveUpdate.leave;
        setRows(newRows);
        clearParamsHoliday();
      },
      onError: (error) => {
        alert(`Произошла ошибка при обновлении ${error.message}`);
      },
    },
  );

  const [linkHoliday] = useMutation(LINK_HOLIDAY, {
    onCompleted: () => {
      const newRows = cloneDeep(rows);
      let indexElem = findIndex(newRows, ['id', selectIdHoliday]);
      newRows[indexElem].userTasksStatus = 'USER_TASKS_LINKED';
      setRows(newRows);
      setSelectIdHoliday(null);
    },
    onError: (error) => {
      alert(`Произошла ошибка при линковке: ${error.message}`);
    },
  });

  const [unlinkHoliday] = useMutation(UNLINK_HOLIDAY, {
    onCompleted: () => {
      const newRows = cloneDeep(rows);
      let indexElem = findIndex(newRows, ['id', selectIdHoliday]);
      newRows[indexElem].userTasksStatus = 'USER_TASKS_UNLINKED';
      setRows(newRows);
      setSelectIdHoliday(null);
    },
    onError: (error) => {
      alert(`Произошла ошибка при разлинковке: ${error.message}`);
    },
  });

  const [deleteHoliday] = useMutation(DELETE_HOLIDAY, {
    onCompleted: (res) => {
      const newRows = cloneDeep(rows);
      let indexElem = findIndex(newRows, ['id', res.userLeaveDelete.leave.id]);
      newRows.splice(indexElem, 1);
      setRows(newRows);
    },
    onError: (error) => {
      alert(`Произошла ошибка при удалении: ${error.message}`);
    },
  });

  useEffect(() => {
    fetchMessages({ variables: {
      userId: userId
    }});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  
  const DEFAULT_PARAMS_HOLIDAY = {
    id: null,
    kind: null,
    startDate: null,
    endDate: null,
    deputy: null,
  };

  const [ paramsHoliday, setParamsHoliday  ] = useState(cloneDeep(DEFAULT_PARAMS_HOLIDAY));

  const [ rows, setRows ] = useState({});
  const [ editHoliday, setEditHoliday ] = useState(false);

  useEffect(() => {
    if (data && data?.user?.leaves) {
      setRows(data.user.leaves)
    }
  }, [data])

  const [ showModalHoliday, setShowModalHoliday] = useState(false);
  const toggleShowModalHoliday = (edit = false, idRow = null) => {
    if (typeof edit === 'boolean' && edit === true) {
      setEditHoliday(idRow || true);
    }

    if (editHoliday && showModalHoliday) {
      setEditHoliday(false);
    }

    setShowModalHoliday(!showModalHoliday);

    if (idRow) {
      let indexElem = findIndex(rows, ['id', idRow]);

      const newParamsHoliday = cloneDeep(paramsHoliday);
      newParamsHoliday.id = rows[indexElem]?.id;
      newParamsHoliday.kind = rows[indexElem]?.kind;
      newParamsHoliday.startDate = rows[indexElem]?.startDate;
      newParamsHoliday.endDate = rows[indexElem]?.endDate;
      newParamsHoliday.deputy = rows[indexElem]?.deputy;

      setParamsHoliday(newParamsHoliday)
    }
  }

  const [hasDateError, setHasDateError] = useState(false);
  const handleChangeDate = useCallback(
    (fieldName) => (value) => {
      let dateValue = null;

      if (value && dayjs(value).isValid()) {
        if (fieldName === 'startDate') {
          dateValue = dayjs(value).utc(true).startOf("day").format(DATE_TIME_FORMAT_NOT_Z);
        } else {
          dateValue = dayjs(value).utc(true).endOf("day").format(DATE_TIME_FORMAT_NOT_Z);
        }
      }

      const newParamsHoliday = cloneDeep(paramsHoliday);
      newParamsHoliday[fieldName] = dateValue;

      setParamsHoliday( newParamsHoliday )
    },
    [paramsHoliday],
  );

  const handleChangeParams = useCallback(
    fieldName => value => {
      const newParamsHoliday = cloneDeep(paramsHoliday);
      if (fieldName ==='deputy') {
        newParamsHoliday[fieldName] = value;
      } else {
        newParamsHoliday[fieldName] = value.target.value;
      }

      if (value?.target?.value === 'DISMISSAL') {
        newParamsHoliday.endDate = null;
      }

      setParamsHoliday( newParamsHoliday );
    }, [paramsHoliday]
  )

  const clearParamsHoliday = () => {
    toggleShowModalHoliday();
    setParamsHoliday(cloneDeep(DEFAULT_PARAMS_HOLIDAY));
  }

  const saveHoliday = () => {
    let cancelRequest = false;

    rows.forEach(elem => {
      if ((dayjs(paramsHoliday.startDate) >= dayjs(elem.startDate) && dayjs(paramsHoliday.startDate) <= dayjs(elem.endDate))  || (dayjs(paramsHoliday.endDate) >= dayjs(elem.startDate) && dayjs(paramsHoliday.endDate) <= dayjs(elem.endDate))) {
        if (paramsHoliday.id === elem.id) {
          return cancelRequest = false;
        } else {
          return cancelRequest = true;
        }
      }
    })

    if (cancelRequest) return (
      alert(`Измените выбранные даты`)
    );

    if (editHoliday) {
      updateHoliday({
        variables: {
          leaveParams: {
            leaveId: editHoliday,
            deputyId: paramsHoliday.deputy.id,
            startDate: paramsHoliday.startDate,
            endDate: paramsHoliday.endDate,
            kind: paramsHoliday.kind,
          }
        },
      })
    } else {
      createHoliday({
        variables: {
          leaveParams: {
            userId: userId,
            deputyId: paramsHoliday.deputy.id,
            startDate: paramsHoliday.startDate,
            endDate: paramsHoliday.endDate,
            kind: paramsHoliday.kind,
          }
        },
      })
    }
  }

  const onLinkHoliday = idHoliday => {
    setSelectIdHoliday(idHoliday);

    linkHoliday({
      variables: {
        leaveId: idHoliday,
      }
    })
  }

  const onUnlinkHoliday = idHoliday => {
    setSelectIdHoliday(idHoliday);

    unlinkHoliday({
      variables: {
        leaveId: idHoliday,
      },
    })
  }

  const onDeleteHoliday = idHoliday => {
    deleteHoliday({
      variables: {
        leaveId: idHoliday,
      }
    })
  }

  const [ disabledButton, setDisabledButton ] = useState(false)

  useEffect(() => {
    for (let elem in paramsHoliday) {
      if (!paramsHoliday[elem]) {
        if (paramsHoliday.kind === 'DISMISSAL' && elem === 'endDate') {
        } else {
          if (elem !== 'id') {
            setDisabledButton(true);
            break;
          }
        }
      } else {
        setDisabledButton(false)
      }
    }

    if (hasDateError) {
      setDisabledButton(true);
    }
  }, [paramsHoliday, hasDateError])

  const [subordinate, setSubordinate] = useState(false) // подчиненный руководителя группы

  useEffect(() => {
    userMiniGroups?.userGroups?.length && userMiniGroups.userGroups.forEach((group) => {
      if (group?.administrator?.user?.id === appData?.currentUser?.id) {
        group.users?.length && group.users.forEach((user) => {
          if (user.id === userId) {
            setSubordinate(true)
          }
        })
      }
    });
  }, [appData?.currentUser?.id, userId, userMiniGroups]);

  const renderModalHoliday = () => {
    let holidayActive = false;

    if (editHoliday && paramsHoliday.endDate && paramsHoliday.endDate && dayjs(paramsHoliday.startDate) <= dayjs().startOf("day") && dayjs().startOf("day") <= dayjs(paramsHoliday.endDate)) {
      holidayActive = true;
    }

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={toggleShowModalHoliday}
        open={showModalHoliday}
        scroll="body"
        sx={{
          '& .MuiPaper-root:not(.MuiAlert-root)': {
            borderRadius: '8px',
          },
        }}
      >
        <DialogTitle
          disableTypography
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', fontSize: '22px', fontWeight: 400, fontFamily: 'Museo Sans Cyrl,Helvetica, sans-serif', }}>
            {editHoliday ? 'Изменить' : 'Добавить'}
          </Box>
          <IconButton onClick={clearParamsHoliday}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers sx={{ minHeight: 150, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', border: 'none', }}>
          <RadioGroup sx={styles.wrapperRadio}>
            {TYPE_HOLIDAYS.map((element, index) => {
              let activeDismissal = !appData?.currentUser?.roles?.includes('ADMIN') && element === 'DISMISSAL';

              return (
                <FormControlLabel
                  checked={paramsHoliday.kind === element}
                  control={<Radio />}
                  disabled={holidayActive || activeDismissal}
                  key={index}
                  label={<FormattedMessage id={`user.status.${element}`} />}
                  onClick={holidayActive ? 'null' : handleChangeParams('kind')}
                  value={element}
                />
              )
            })}
          </RadioGroup>

          <Box sx={{ display: 'flex', marginBottom: '43px' }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Typography sx={{ fontSize: '12px', fontWeight: 400, marginBottom: '6px', fontFamily: 'Museo Sans Cyrl,Helvetica, sans-serif', }}>Дата начала</Typography>
              <DatePickerBox
                disabled={holidayActive}
                maxDate={paramsHoliday?.endDate || undefined}
                maxDateMessage={
                  paramsHoliday.endDate
                    ? `Не может быть более ${formatDate(paramsHoliday.endDate)}`
                    : undefined
                }
                onChange={handleChangeDate('startDate')}
                onError={setHasDateError}
                placeholder="дд/мм/ггг"
                value={paramsHoliday.startDate}
              />
            </Box>
            &nbsp;&nbsp;
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Typography sx={{ fontSize: '12px', fontWeight: 400, marginBottom: '6px', fontFamily: 'Museo Sans Cyrl,Helvetica, sans-serif', }}>Дата окончания</Typography>
              <DatePickerBox
                disabled={paramsHoliday.kind === "DISMISSAL" || holidayActive}
                minDate={paramsHoliday?.startDate || undefined}
                minDateMessage={
                  paramsHoliday.startDate
                    ? `Не может быть ранее ${formatDate(paramsHoliday.startDate)}`
                    : undefined
                }
                onChange={handleChangeDate('endDate')}
                onError={setHasDateError}
                placeholder="дд/мм/ггг"
                value={paramsHoliday.endDate}
              />
            </Box>
          </Box>

          <Box sx={{ width: '100%' }}>
            <Typography sx={{ fontSize: '12px', fontWeight: 400, marginBottom: '6px', fontFamily: 'Museo Sans Cyrl,Helvetica, sans-serif', }}>Заместитель</Typography>
            <AutocompleteTaskUsers
              onChange={handleChangeParams('deputy')}
              value={paramsHoliday.deputy || ''}
            />
          </Box>

          <Box sx={{ marginTop: '25px' }}>
            <Box
              component={"button"}
              disabled={disabledButton}
              onClick={saveHoliday}
              sx={ styles.buttonSend }
            >
              {editHoliday ? 'Сохранить' : 'Создать'}
            </Box>
            <Box
              component={'button'}
              onClick={clearParamsHoliday}
              sx={{
                border: 'none',
                fontSize: '14px',
                fontWeight: 400,
                color: '#6E7884',
                padding: '15px 26px',
                backgroundColor: '#F5F6F7',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
            >
              Отмена
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  const [ showModalDetailUser, setShowModalDetailUser ] = useState(false);
  const [ detailModalUser, setDetailModalUser ] = useState(null);
  const onShowModalDetailUser = (bool, userId) => {
    setDetailModalUser(userId);
    setShowModalDetailUser(bool);
  }

  const renderContent = () => {
    return (
      <Box sx={{ height: '100%', width: '100%' }}>
        <Box
          sx={{
            margin: '0 24px',
            padding: '24px 0 0 0',
          }}
        >
          <Link
            href={'/collaborators'}
            sx={{
              color: '#6E7884',
              fontSize: '13px',
              display: 'flex',
              alignItems: 'center',
              width: '90px',
              fontFamily: 'Museo Sans Cyrl,Helvetica, sans-serif',
              fontWeight: 400,
            }}
          >
            <KeyboardArrowLeftIcon sx={{ color: '#B6BBC2', }} /> Вернуться
          </Link>
        </Box>

        <Box sx={styles.wrapperContent}>
          <Typography sx={{
            fontSize: '26px',
            color: '#0D1D32',
            borderBottom: '1px solid #E7E8EA',
            width: '100%',
            padding: '15px 24px',
            fontFamily: 'Museo Sans Cyrl,Helvetica, sans-serif',
          }}>
            {data?.user?.contact?.firstName ? data.user.contact.firstName : null}
            {" "}
            {data?.user?.contact?.middleName ? data.user.contact.middleName : null}
            {" "}
            {data?.user?.contact?.lastName ? data.user.contact.lastName : null}
          </Typography>

          <Box sx={{
            padding: '24px',
            width: '100%',
          }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px',
              }}
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400, fontFamily: 'Museo Sans Cyrl,Helvetica, sans-serif', }}>Отпуска / больничные:</Typography>

              {(appData?.currentUser?.id === userId || appData?.currentUser?.roles?.includes('ADMIN') || subordinate) && (
                <Box
                  component={'button'}
                  onClick={toggleShowModalHoliday}
                  sx={{
                    backgroundColor: '#3D6CE7',
                    padding: '11px 18px',
                    margin: 0,
                    color: '#ffffff',
                    border: 'none',
                    borderRadius: '4px',
                    fontSize: '14px',
                    fontWeight: 400,
                    display: 'flex',
                    cursor: 'pointer',
                  }}
                >
                  <AddIcon sx={{ color: '#fff', height: '16px', width: '16px', marginRight: '7px' }} />
                  Добавить
                </Box>
              )}
            </Box>

            <Box sx={styles.wrapperTable}>
              <Grid
                columns={COLUMNS_MODEL_CREATOR_HOLIDAY_USER}
                getRowId={(row) => row.id}
                rootComponent={GridRoot}
                rows={rows}
              >
                <RowDetailState />

                {(appData?.currentUser?.id === userId || appData?.currentUser?.roles?.includes('ADMIN') || subordinate) && (
                  <DocumentActionTypeProviderHoliday
                    for={['actions']}
                    onDeleteHoliday={onDeleteHoliday}
                    onLinkHoliday={onLinkHoliday}
                    onToggleModal={toggleShowModalHoliday}
                    onUnlinkHoliday={onUnlinkHoliday}
                  />
                )}

                <DocumentActionTypeProviderDetailUser
                  for={['deputy']}
                  onToggleModal={onShowModalDetailUser}
                />

                <VirtualTable
                  cellComponent={(props) => (
                    <VirtualTable.Cell className="HolidayTableCell" {...props} />
                  )}
                  columnExtensions={COLUMN_EXTENSIONS_HOLIDAY_USER}
                  messages={{ noData: loading ? 'Загрузка...' : 'Нет данных' }}
                />
                <TableHeaderRow />
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ErrorAlert message={error.message} title="Ошибка при выполнении запроса" />
  }

  return (
    <Box>
      <Box sx={{ backgroundColor: '#f3f4f5', paddingBottom: '20px',}}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 24px', backgroundColor: '#ffffff', height: '100px' }}>
          <Typography gutterBottom sx={{ fontSize: '32px !important', margin: '0 !important', width: '140px', }} variant="h2">
            Сотрудники
          </Typography>
        </Box>

        <CollaboratorsTabs />

        {state.filter.tab === TABS[0] && (
          renderContent()
        )}

        {state.filter.tab === TABS[1] && (
          <Box sx={{ ...styles.wrapperContent, padding: '24px'}}>
            <PageTemplate />
          </Box>
        )}

        {state.filter.tab === TABS[2] && (
          <Box sx={{ ...styles.wrapperContent, padding: '24px'}}>
            <PageGraphHolidays />
          </Box>
        )}
      </Box>

      {showModalHoliday && renderModalHoliday()}

      {showModalDetailUser && (
        <DetailsModalInfUser
          onModalClose={onShowModalDetailUser.bind(this, false)}
          userId={detailModalUser}
        />
      )}
    </Box>
  );
}