import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { DevTool } from '@hookform/devtools';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  TextField,
  Toolbar,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import dayjs from "dayjs";
import _noop from 'lodash.noop';
import { Controller, useForm } from 'react-hook-form';

import { formatDate } from 'utils/dateTimeFormatters';

import { SUPPLYING_ORDERS_CREATE_SERVICE_ACCEPTANCE_CERTIFICATE } from './graphql/mutations/supplyingOrdersCreateServiceAcceptanceCertificate';
import CurrencyFormatter from "../../components/CurrencyFormatter";

const useStyles = makeStyles((theme) => ({
  root: {
    '& input': {
      fontWeight: 500,
      '&::placeholder': {
        fontWeight: 400,
      },
    },
    '& textarea': {
      fontWeight: 500,
      '&::placeholder': {
        fontWeight: 400,
      },
    },
  },
  focused: {
    backgroundColor: '#fff',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto 1fr',
    gridColumnGap: '16px',
    alignItems: 'center',
  },
}));

const POSITIONS = [
  {
    nominative: 'Генеральный директор',
    genitive: 'Генерального директора',
  },
];

const filter = createFilterOptions();

const outlineFieldStyleProps = {
  variant: 'outlined',
  color: 'secondary',
  fullWidth: true,
  margin: 'dense',
};

FormServiceAcceptanceCertificate.propTypes = {
  defaultValues: PropTypes.object,
  invoice: PropTypes.object,
  onSuccess: PropTypes.func,
  supplies: PropTypes.array,
};

export default function FormServiceAcceptanceCertificate({
  defaultValues,
  invoice,
  onSuccess = _noop,
  supplies = [],
}) {
  const { control, errors, formState, handleSubmit, register, setError, setValue } = useForm({
    defaultValues,
  });

  const [generateDoc, { data, loading }] = useMutation(
    SUPPLYING_ORDERS_CREATE_SERVICE_ACCEPTANCE_CERTIFICATE,
  );

  const [loanSupplyIds, setLoanSupplyIds] = useState([]);

  const onChangeLoanSupplyIds = (_, arr) => {
    let newArr = [];

    arr.forEach(el => {
      newArr.push(el.id);

    })
    setLoanSupplyIds(newArr);
  }

  const [loanInvoice, setLoanInvoice] = useState(null);

  const onChangeInvoiceId = (_, val) => {
    setLoanInvoice(val)
  }

  async function onSubmit({ documentDate, ...data }) {
    let variables = {
      attributes: {
        ...data,
        documentDate: documentDate ? formatDate(documentDate, 'full') : '',
      },
    }

    if (loanInvoice) {
      variables.invoiceId = loanInvoice?.id;
    }

    if (loanSupplyIds.length) {
      variables.loanSupplyIds = loanSupplyIds;
    }

    try {
      await generateDoc({
        variables,
      });
    } catch (error) {
      setError('FORM_ERROR', {
        message: error.message,
      });
    }
  }

  const classes = useStyles();

  const documentDateField = (
    <Controller
      control={control}
      defaultValue={null}
      name="documentDate"
      render={(props) => (
        <KeyboardDatePicker
          {...outlineFieldStyleProps}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          disableToolbar
          format="dd/MM/yyyy"
          inputRef={register}
          inputVariant="outlined"
          // eslint-disable-next-line react/prop-types
          onChange={(date) => props.onChange(date)}
          placeholder="25/03/21"
          // eslint-disable-next-line react/prop-types
          value={props.value}
          variant="inline"
        />
      )}
    />
  );

  const borrowerInChargePositionGenitiveField = (
    <AutocompleteFreeSolo
      onChange={(newValue) => {
        setValue('borrowerInChargePosition', newValue?.nominative || '');
      }}
      options={POSITIONS}
      renderInput={(params) => (
        <TextField
          {...params}
          {...outlineFieldStyleProps}
          inputRef={register}
          name="borrowerInChargePositionGenitive"
          placeholder="Генерального директора"
        />
      )}
      value={defaultValues?.borrowerInChargePositionGenitive}
      valueKey="genitive"
    />
  );

  const borrowerInChargePositionField = (
    <TextField
      {...outlineFieldStyleProps}
      InputLabelProps={{ shrink: true }}
      inputRef={register}
      label="Должность в именительном падеже"
      name="borrowerInChargePosition"
      placeholder="Генеральный директор"
    />
  );

  const borrowerInChargeFullNameField = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      name="borrowerInChargeFullName"
      placeholder="Петрова Ивана Николаевича"
    />
  );

  const borrowerInChargeShortNameField = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      name="borrowerInChargeShortName"
      placeholder="И.О. Фамилия"
    />
  );

  const basedOnField = (
    <TextField
      {...outlineFieldStyleProps}
      inputRef={register}
      name="basedOn"
      placeholder="И.О. Фамилия"
    />
  );

  return (
    <>
      <Box
        className={classes.root}
        component="form"
        maxWidth={1024}
        minWidth={720}
        onSubmit={handleSubmit(onSubmit)}
        width={1}
      >
        <Toolbar disableGutters>
          <Button
            color="primary"
            disableElevation
            disabled={loading || !(loanSupplyIds?.length !== 0 || loanInvoice !== null)}
            type="submit"
            variant="outlined"
          >
            Создать документ
          </Button>
          {formState.isSubmitSuccessful && (
            <Box
              component="a"
              href={data?.supplyingOrdersCreateServiceAcceptanceCertificate.document.assetUrl}
              p={2}
              target="_blank"
            >
              Скачать документ
            </Box>
          )}
        </Toolbar>

        {errors['FORM_ERROR'] && (
          <Alert severity="error" sx={{ marginBottom: "20px" }}>
            <AlertTitle>
              <Box mt={1}>Произошла ошибка при генерации документа</Box>
            </AlertTitle>
            <Typography variant="body2">Мы уже знаем об этом и скоро исправим</Typography>
          </Alert>
        )}

        <Box className={classes.grid} mb={3}>
          <Box gridColumn="1" gridRow="1">
            <Typography>Отгрузка</Typography>
          </Box>

          <Box gridColumn="2" gridRow="1">
            <Autocomplete
              clearText="Очистить"
              closeText="Скрыть список"
              disablePortal
              disabled={loanInvoice?.sourceDocument?.id}
              getOptionLabel={(option) => {
                return (`Сумма: ${option.amount} Дата: ${dayjs(option.date).format('DD.MM.YYYY')}`)
              }}
              multiple
              noOptionsText={'Нет данных'}
              onChange={onChangeLoanSupplyIds}
              openText="Показать список"
              options={supplies}
              renderInput={(params) => <TextField {...params} />}
              renderOption={(option, props) => {
                return (
                  <li {...props}>
                    Сумма: <CurrencyFormatter value={option?.amount} /> Дата: {dayjs(option.date).format('DD.MM.YYYY')}
                  </li>
                )
              }}
            />
          </Box>
        </Box>

        <Box className={classes.grid} mb={3}>
          <Box gridColumn="1" gridRow="1">
            <Typography>Счёт</Typography>
          </Box>

          <Autocomplete
            clearText="Очистить"
            closeText="Скрыть список"
            disabled={loanSupplyIds?.length}
            fullWidth
            getOptionLabel={(option) => option.sourceDocument.assetOriginalFilename}
            noOptionsText={'Нет данных'}
            onChange={onChangeInvoiceId}
            openText="Показать список"
            options={[invoice]}
            renderInput={(params) => (
              <TextField  {...params}  />
            )}
            value={loanInvoice}
          />
        </Box>

        <Box className={classes.grid} mb={3}>
          <Box gridColumn="1" gridRow="1">
            <Typography>Дата документа</Typography>
          </Box>
          <Box gridColumn="2" gridRow="1">
            {documentDateField}
          </Box>
        </Box>
        <Box mb={3}>
          <Typography gutterBottom variant="h5">
            В лице
          </Typography>
          <Box alignItems="center" display="flex">
            <Box flex="1" mr={3}>
              {borrowerInChargePositionGenitiveField}
            </Box>
            <Box flex="1">{borrowerInChargePositionField}</Box>
          </Box>
          <Box alignItems="center" display="flex">
            <Box flex="1" mr={3}>
              {borrowerInChargeFullNameField}
            </Box>
            <Box flex="1">{borrowerInChargeShortNameField}</Box>
          </Box>
        </Box>
        <Box alignItems="center" display="flex" mb={3}>
          <Typography>действующего на основании</Typography>
          <Box flex="1" ml={3}>
            {basedOnField}
          </Box>
        </Box>
      </Box>
      <DevTool control={control} />
    </>
  );
}

AutocompleteFreeSolo.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  renderInput: PropTypes.func,
  value: PropTypes.string,
  valueKey: PropTypes.string,
};

function AutocompleteFreeSolo({ onChange = _noop, options, renderInput, value, valueKey }) {
  const [_value, setAutocompleteValue] = useState(value || null);

  function filterOptions(options, params) {
    const filtered = filter(options, params);

    // Suggest the creation of a new value
    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        [valueKey]: `Добавить "${params.inputValue}"`,
      });
    }

    return filtered;
  }

  function getOptionLabel(option) {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option[valueKey];
  }

  function renderOption(option) {
    return option[valueKey];
  }

  function handleChange(_, newValue) {
    if (typeof newValue === 'string') {
      setAutocompleteValue({
        [valueKey]: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setAutocompleteValue({
        [valueKey]: newValue.inputValue,
      });
    } else {
      setAutocompleteValue(newValue);
      onChange(newValue);
    }
  }

  return (
    <Autocomplete
      filterOptions={filterOptions}
      freeSolo
      getOptionLabel={getOptionLabel}
      handleHomeEndKeys
      onChange={handleChange}
      options={options}
      renderInput={renderInput}
      renderOption={renderOption}
      selectOnFocus
      value={_value}
    />
  );
}
