import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {useLazyQuery} from "@apollo/client";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage } from "react-intl";

import AvatarUser from "../../../components/AvatarUser";
import ErrorAlert from "../../../components/ErrorAlert";
import Phone from "../../../components/Phone";
import WazzupLink from "../../../components/WazzupLink";
import { formatDate } from "../../../utils/dateTimeFormatters";
import { Loader } from "../../deals/components/helpers";
import { GET_USER_DETAIL } from "../graphql/queries/userDetail";
import clock from '../images/clock.svg';
import substitute from '../images/substitute.svg';

DetailsModalInfUser.propTypes = {
  onModalClose: PropTypes.func,
  userId: PropTypes.number.isRequired,
}
export default function DetailsModalInfUser({ onModalClose, userId }) {
  const styles = useMemo(() => ({
    wrapperBlock: {
      fontSize: '16px',
      color: '#0D1D32',
      marginBottom: '18px',
      fontWeight: 400,

      '& p': {
        color: '#6E7884',
        fontWeight: 400,
        fontSize: '12px',
        fontFamily: 'Museo Sans Cyrl,Helvetica, sans-serif',
      },
      '& a': {
        color: '#0D1D32',
        fontWeight: 400,
        fontSize: '16px',
      },
      '& span': {
        color: '#0D1D32',
        fontWeight: 400,
        fontSize: '16px',
      },
    },
    wrapperAvatar: {

      '& > div': {
        width: '190px',
        height: '190px',
        fontSize: '48px',
      },
      '& > div > div': {
        width: 'auto',
        height: '190px',
      }
    }
  }), []);

  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => {
    setOpen(false);
    if (onModalClose && typeof onModalClose === 'function') {
      onModalClose(false)
    }
  }, [onModalClose]);

  const [fetchMessages, { data, error, loading }] = useLazyQuery(GET_USER_DETAIL);

  const [detailUser, setDetailUser] = useState({});

  useEffect(() => {
    fetchMessages({ variables: {
        userId: userId
      }});
  }, [fetchMessages, userId])

  useEffect(() => {
    if (data) {
      setDetailUser(data.user)
    }
  }, [data])

  useEffect(() => {
    if (userId) {
      handleOpen();
    }
  }, [userId, handleOpen]);

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ErrorAlert message={error.message} title="Ошибка при выполнении запроса" />
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={open}
      scroll="body"
      sx={{
        '& .MuiPaper-root:not(.MuiAlert-root)': {
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle
        disableTypography
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          color: '#0D1D32',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', paddingTop: '12px' }}>
          <Typography sx={{ width: '100%', fontSize: '22px', marginBottom: '25px', fontFamily: 'Museo Sans Cyrl,Helvetica, sans-serif', fontWeight: 400, }} variant="h3">
            {detailUser?.contact?.firstName}
            {" "}
            {detailUser.contact?.middleName}
            {" "}
            {detailUser?.contact?.lastName}
          </Typography>

          {detailUser?.leaves && detailUser.leaves.length > 0  && (
            // eslint-disable-next-line array-callback-return
            detailUser?.leaves.map(elem => {
              if (elem.userTasksStatus === 'USER_TASKS_LINKED') {
                return (
                  <>
                    <Typography sx={{ width: '100%', fontSize: '14px', display: 'flex', marginBottom: '11px', fontWeight: 300, }}>
                      <Box component={"img"} src={clock} sx={{marginRight: '8px'}} />
                      <Typography sx={{ fontWeight: 400, fontSize: '14px', marginRight: '4px', fontFamily: 'Museo Sans Cyrl,Helvetica, sans-serif', }}>
                        <FormattedMessage id={`user.status.${elem.kind}`} />:
                      </Typography>
                      {elem.startDate && (
                        <>
                          {" "}
                          c {formatDate(elem.startDate)}
                        </>
                      )}
                      {elem.endDate && (
                        <>
                          {" "}
                          до {formatDate(elem.endDate)}
                        </>
                      )}
                    </Typography>

                    {detailUser?.deputy && (
                      <Typography sx={{ width: '100%', fontSize: '14px', display: 'flex', fontWeight: 300, }}>
                        <Box component={"img"} src={substitute} sx={{marginRight: '8px'}} />
                        <Typography sx={{ fontWeight: 400, fontSize: '14px', marginRight: '4px',fontFamily: 'Museo Sans Cyrl,Helvetica, sans-serif', }}>Замещает:</Typography>
                        {detailUser.deputy.displayName}
                      </Typography>
                    )}
                  </>
                )
              }
            })
          )}
        </Box>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers sx={{ minHeight: 150, display: 'flex', justifyContent: 'space-between', color: '#0D1D32', }}>
        <Box>
          <Box sx={ styles.wrapperBlock }>
            <Typography>Рабочий email (Логин):</Typography>
            {detailUser?.contact?.email ? (
              <Link href={`mailto:${detailUser?.contact?.email}`}>
                {detailUser?.contact?.email}
              </Link>
            ) : 'Не указано'}
          </Box>

          <Box sx={ styles.wrapperBlock }>
            <Typography>Дополнительный email:</Typography>
            {detailUser?.contact?.additionalEmail ? (
              <Link href={`mailto:${detailUser?.contact?.additionalEmail}`}>
                {detailUser.contact.additionalEmail}
              </Link>
            ) : 'Не указано'}
          </Box>

          <Box sx={ styles.wrapperBlock }>
            <Typography>Рабочий телефон:</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Phone phone={detailUser?.contact?.phone} />

              <WazzupLink phone={detailUser?.contact?.phone} />
            </Box>
          </Box>

          <Box sx={ styles.wrapperBlock }>
            <Typography>Дополнительные телефоны:</Typography>
            {detailUser?.contact?.additionalPhoneNumbers && detailUser.contact.additionalPhoneNumbers.length > 0 ? (
              detailUser.contact.additionalPhoneNumbers.map((elem, index) => (
                <Typography component={'div'} key={index} sx={{ display: 'block', }}>
                  <Phone phone={elem?.phone} />
                  <WazzupLink phone={elem.phone} sx={{ marginRight: '6px', display: 'inline-block' }} />
                  ({elem.comment})
                </Typography>
              ))
            ) : 'Не указано'}
          </Box>

          <Box sx={ styles.wrapperBlock }>
            <Typography>Дата рождения:</Typography>
            {detailUser?.contact?.dateOfBirth ? formatDate(detailUser.contact.dateOfBirth) : 'Не указано'}
          </Box>

          <Box sx={ styles.wrapperBlock }>
            <Typography>Роли:</Typography>
            {detailUser?.roles && detailUser?.roles.length > 0 && (
              detailUser?.roles.map((elem, index) => (
                <Typography component={'span'} key={index}>
                  <FormattedMessage id={`user.role.${elem}`} />
                  {detailUser?.roles.length - 1 === index ? null : ','} {" "}
                </Typography>
              ))
            )}
          </Box>
        </Box>
        <Box sx={styles.wrapperAvatar}>
          <AvatarUser avatar={detailUser?.avatar} firstName={detailUser?.contact?.firstName} lastName={detailUser?.contact?.lastName} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}