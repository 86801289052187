// Добавляя категорию, необходимо
// - добавить перевод в /Users/s.reutskiy/sources/arenza/crm-frontend/src/locales/ru.json
// - добавить цвет в /Users/s.reutskiy/sources/arenza/crm-frontend/src/core/ThemeWrapper.jsx

export const CATEGORIES = [
  'budget',
  'bank',
  'core',
  'housekeeping',
  'leasing',
  'credits',
  'individuals',
  'rent',
  'cash',
  'affiliate_settlements',
  'other',
  'own_funds'
];

export const STATUS_PROCESSING = ['CREATED', 'CREATING_ACCOUNTS', 'CREATING_ORDERS', 'ES_INDEXING', 'PARSING', 'UPDATING_INFO'];