import { gql } from '@apollo/client/core';

const ENTITY_FIELDS = gql`
  fragment EntityFields on Entity {
    id
    legalName
    shortLegalName
    tin
    psrn
    dadataStorage
    organizationalForm 
    addresses {
      id
      fullAddress
      type
    }
    staff {
      id
      position
      contact {
        firstName
        middleName
        lastName
        shortName @client
      }
    }
  }
`;

export const GET_SUPPLYING_ORDER = gql`
  ${ENTITY_FIELDS}
  query GetSupplyingOrder($supplyingOrderId: ID!) {
    supplyingOrder(supplyingOrderId: $supplyingOrderId) {
      id
      number
      status
      application {
        id
        subject
        borrowerEntity {
          ...EntityFields
        }
      }
      supplier {
        ...EntityFields
        contracts {
          id
          signingMethod
          signedAt
          category
          userId
          createdAt
          updatedAt
          title
          additionalAgreementKind
          expirationDate
          requirements
          status
          isActive
        }
      }
      supplies {
        id
        amount
        date
      }
      invoice {
        id
        sourceDocument {
          id
          assetUrl
          assetOriginalFilename
          createdAt
        }
      }
      sellerDiff
      sellerBankData
      sellerDiffIgnored
    }
  }
`;
